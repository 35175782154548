<template>
  <div class="login-wrapper">
    <p-header type="flow" :without-login="true"></p-header>
    <main>
      <div class="content-box">
        <h3 class="title">直播电商供应链服务平台</h3>
        <div class="desc">为数百万网红及数万商家提供专属服务</div>
        <div class="login-platforms">
          <div class="platform-item">
            <div class="box">
              <div class="btn">
                <img src="https://mktv-in-cdn.mockuai.com/16276591280216601.png" alt="" class="icon">
                <span class="text">主播登录</span>
              </div>
            </div>
            <div class="hover-box">
              <a href="https://shop.mockuai.com/pages/login?type=shopkeeper" target="_blank" class="btn">我是店主</a>
              <a href="https://shop.mockuai.com/pages/login?type=employee" target="_blank" class="btn">我是员工</a>
            </div>
          </div>
          <div class="platform-item">
            <div class="box">
              <div class="btn">
                <img src="https://mktv-in-cdn.mockuai.com/16276591280117393.png" alt="" class="icon">
                <span class="text">商家登录</span>
              </div>
            </div>
            <div class="hover-box">
              <a href="https://supplier.mockuai.com/pages/login?type=supplier" target="_blank" class="btn">我是品牌商</a>
              <a href="https://supplier.mockuai.com/pages/login?type=employee" target="_blank" class="btn">我是员工</a>
            </div>
          </div>
          <div class="platform-item scrm">
            <div class="box" @click="jumpScrm">
              <div class="btn">
                <img src="https://mktv-in-cdn.mockuai.com/16276591280185374.png" alt="" class="icon">
                <span class="text">酷管家登录</span>
              </div>
            </div>
            <!-- <div class="hover-box">
              <a href="https://scrm.mockuai.com/qrCodeLogin?origin=official" target="_blank" class="btn">企业微信扫码</a>
              <a href="https://mktv-in-cdn.mockuai.com/16277235745551115.png" target="_blank" class="btn">立即免费试用</a>
            </div> -->
          </div>
        </div>
      </div>
    </main>
    <div class="footer-box">
      <p-footer type="flow" theme="transparent"></p-footer>
    </div>
  </div> 
</template>

<script>
  import Header from '../layout/Header.vue'
  import Footer from '../layout/Footer.vue'

  export default {
    components: {
      'p-header': Header,
      'p-footer': Footer
    },
    methods: {
      jumpScrm() {
        window.open('https://scrm.mockuai.com/qrCodeLogin?redirect=%2Findex')
      }
    }
  }
</script>

<style lang="less" scoped>
  @import '../style/common.less';

  .login-wrapper {
    box-sizing: border-box;
    min-height: 100vh;
    padding-top: 90px;
    background: url(https://mktv-in-cdn.mockuai.com/16276566795813067.png) top left/cover no-repeat, linear-gradient(270deg, #001554 0%, #00092A 100%);
    main {
      padding: 272*@unitVw 0;
      .content-box {
        .pxToVw(width, 1200);
        margin: 0 auto;
        h3.title {
          text-align: center;
          .pxToVw(margin-bottom, 17);
          .pxToVw(line-height, 112);
          .pxToVw(font-size, 80);
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #FFFFFF;
        }
        .desc {
          .pxToVw(margin-bottom, 50);
          .pxToVw(line-height, 33);
          .pxToVw(font-size, 24);
          text-align: center;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #FFFFFF;
        }
        .login-platforms {
          display: flex;
          align-items: center;
          justify-content: center;
          .platform-item {
            position: relative;
            .pxToVw(margin-right, 64);
            .pxToVw(width, 180);
            .pxToVw(height, 104);
            &:last-child {
              margin-right: 0;
            }
            .box, .hover-box {
              position: absolute;
              bottom: 0;
              left: 0;
              width: 100%;
            }
            .box {
              visibility: visible
            }
            .hover-box {
              visibility: hidden;
              .btn {
                &:nth-child(1) {
                  border-bottom-left-radius: 0;
                  border-bottom-right-radius: 0;
                  border-bottom: 1px solid rgba(255, 255, 255, .2);
                }
                &:nth-child(2) {
                  border-top-left-radius: 0;
                  border-top-right-radius: 0;
                }
              }
            }
            &:hover {
              .box {
                visibility: hidden;
              }
              .hover-box {
                visibility: visible;
              }
            }
            &.scrm {
              &:hover {
                .box {
                  visibility: visible;
                }
              }
            }
          }
          .btn {
            text-decoration: none;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: center;
            .pxToVw(width, 180);
            .pxToVw(height, 52);
            .pxToVw(border-radius, 4);
            background-color: #1989fa;
            .pxToVw(font-size, 18);
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            cursor: pointer;
            .icon {
              .pxToVw(width, 48);
              .pxToVw(height, 48);
              .pxToVw(margin-right, 11);
            }
            .text {
              .pxToVw(font-size, 18);
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #FFFFFF;
            }
          }
        }
      }
    }
  }

  @media screen and(min-width: 1920px){
    .login-wrapper {
      main {
        .content-box {
          width: 1200px;
          h3.title {
            line-height: 112px;
            font-size: 80px;
            margin-bottom: 17px;
          }
          .desc {
            line-height: 33px;
            font-size: 24px;
            margin-bottom: 50px;
          }
          .login-platforms {
            .platform-item {
              width: 180px;
              height: 104px;
              margin-right: 64px;
            }
            .btn {
              width: 180px;
              height: 52px;
              font-size: 18px;
              .icon {
                width: 48px;
                height: 48px;
              }
              .text {
                font-size: 18px;
              }
            }
          }
        }
      }
    }
  }

  @media screen and(max-width: 1066px) {
    .login-wrapper {
       main {
        padding: 151.017px 0;
        .content-box {
          width: 666.25px;
          h3.title {
            font-size: 44.4167px;
            line-height: 62.1833px;
            margin-bottom: 9.43854px;
          }
          .desc {
            font-size: 13.325px;
            line-height: 18.3219px;
            margin-bottom: 27.7604px;
          }
          .login-platforms {
            .platform-item {
              margin-right: 35.5333px;
              width: 99.9375px;
              height: 57.7344px;
            }
            .btn {
              width: 99.9375px;
              height: 28.8594px;
              font-size: 10px;
              .icon {
                width: 26.6406px;
                height: 26.6406px;
              }
              .text {
                font-size: 10px;
              }
            }
          }
        }
      }
    }
  }
</style>